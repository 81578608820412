import React from 'react'
import Layout from 'layouts/fr'
import styled from 'styled-components'
import design from 'design'
import {
  Title,
  Text,
  Row,
  Div,
  Box,
  Button,
  Input,
  Table,
  FullWidthBg
} from 'components'
import {TryOrSchedule} from "containers/fr";
import StairlinShip from "images/StairlinShip8bitcopy.png";
import IslandFinance from "images/PaperworkPassagebig8bitcopy.png";
import Phone from '../images/phone.jpg'
import messages from '../images/messages.png'
import BALogo from '../images/ba-logo.png'
import BareArrow from '../images/arrow.inline.svg'
import BareChart from '../images/chart.svg'
import Moment from 'react-moment'
import { format } from 'utils'

const formats = format('fr')

const Arrow = styled(BareArrow)`
  width: 8px;
  height: 14px;
  transform: rotate(-90deg);
`
const Chart = styled(BareChart)`
  width: 100%;
  height: auto;
`

export default () => {
  return (
    <Layout>
      <Box position={'relative'} mb={[6,8]} mt={[4,7]} bg={['transparent','white']} boxShadow={['none','black']} p={[0,6]}>

        <Div display={['none','block']} position={'absolute'} top={-40} left={-20}>
          <img src={StairlinShip} width={231} alt={''} />
        </Div>
        <Div display={['none','block']} position={'absolute'} bottom={-52} right={-32}>
          <img src={IslandFinance} width={274} alt={''} />
        </Div>

        <Div maxWidth={['none','55%']} margin={'0 auto'} textAlign={'center'}>
          <Title mb={[2,6]} fontSize={[6,8]}>Réduisez votre charge administrative de moitié</Title>
          <Text tag={'h2'} mb={[4,6]} fontSize={[3,6]}>Libérez-vous de la lourdeur du travail administratif grâce aux fonctionnalités intuitives et faites sur mesure pour les entrepreneurs solos. Stairlin vous aide à vous concentrer sur ce qui compte le plus, vos clients !</Text>
          <Div mt={[0,6]} bg={['transparent']}>
            <form action="https://stairlin.com/go" method="get">
              <Div display={['block','flex']}>
                <Input name="email" fontSize={[4,4]} p={[3,4]} placeholder={`Votre email`} /><Button width={['100%','auto']} type={'submit'} bg={'primary'} display={['flex','inline-flex']} color={'white'} boxShadow={'primary'} px={[4,4]} py={[3,4]} fontSize={[4,6]} ml={[0,4]} mt={[3,0]}>Démarrer maintenant</Button>
              </Div>
            </form>
            <Text mt={[4,5]} fontSize={2} color={'#5D6F71'}>Installation simple • Pas de frais initiaux • Pas de carte de crédit requise • Pas de frais de résiliation</Text>
          </Div>
        </Div>
      </Box>

      <Div tag={'section'}>
        <Div id={'Billing'} display={'flex'} mb={[5,7]}>
          <Title href={'/fr/finance#Billing'} mr={6} fontSize={[5,8]} fontWeight={700}>Facturation</Title>
          <Title href={'/fr/finance#Accounting'} mr={[0,6]} fontSize={[5,8]} fontWeight={400}>Comptabilité</Title>
        </Div>

        <Div id='Prepare' display={['block','flex']}>
          <Div flex={1}>
            <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
              <Title href={'/fr/finance#Prepare'} mr={[`102px`,6]} mb={4} fontSize={[3,4]} fontWeight={700}>Préparer</Title>
              <Title href={'/fr/finance#Release'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Envoyer</Title>
              <Title href={'/fr/finance#Supervise'} mr={[`88px`,6]} mb={4} fontSize={[3,4]} fontWeight={400}>Superviser</Title>
              <Title href={'/fr/finance#Credit'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Crédit</Title>
            </Div>
            <Div mr={[0,6]} textAlign={['center','left']}>
              <Title mb={[4,6]} fontSize={[6,7]}>Optimisez la création, l'envoi et le suivi de vos factures</Title>
              <Text mb={[4,6]} fontSize={[3,4]}>La génération de vos factures est automatisée depuis vos réservations</Text>
            </Div>
          </Div>
          <Div flex={1}>
            <Box bg={'white'} position={'relative'} boxShadow={['none','black']} m={0} mb={[110,0]} mx={[-36,0]} px={[4,5]} py={[5,6]}>
              <Div display={'flex'} justifyContent={'space-between'}>
                <Div>
                  <Text fontWeight={700} fontSize={[3,5]} mb={4}>Solde Client</Text>
                  <Text fontWeight={700} fontSize={[5,6]} mb={[6,7]}>CHF 120.00</Text>
                </Div>
                <Div display={['none','block']}>
                  <Button boxShadow={'primary'} bg={'primary'} color={'white'} fontSize={[1]}>Facturer maintenant</Button>
                </Div>
              </Div>

              <Div mb={[6,7]}>
                <Text fontWeight={700} fontSize={[3,5]} mb={[4,5]}>Paiements
                </Text>
                <Div display={['flex']} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'baseline'} mb={[3]} px={[3,0]} py={[4,0]} border={[`1px solid ${design.color.grayLight}`,'none']} borderRadius={5}>
                  <Text fontWeight={700} fontSize={[3,5]} _mb={[3,4]}>CHF 120.00</Text>
                  <Text flexBasis={['auto',110]} color={'green'} fontWeight={700} fontSize={[2,3]} _mb={[3,4]}>Payé</Text>
                  <Text flexBasis={['100%','auto']} color={'grayDark'} fontWeight={400} fontSize={[2,3]}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                </Div>
              </Div>

              <Div mb={[6,7]}>
                <Text fontWeight={700} fontSize={[3,5]} mb={[4,5]}>Factures</Text>
                <Div display={['flex']} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'baseline'} mb={[3]} px={[3,0]} py={[4,0]} border={[`1px solid ${design.color.grayLight}`,'none']} borderRadius={5}>
                  <Text fontWeight={700} fontSize={[3,5]} _mb={[3,4]}>CHF 120.00</Text>
                  <Text flexBasis={['auto',110]} color={'green'} fontWeight={700} fontSize={[2,3]} _mb={[3,4]}>Payé</Text>
                  <Text flexBasis={['100%','auto']} color={'grayDark'} fontWeight={400} fontSize={[2,3]}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                </Div>
                <Div display={['flex']} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'baseline'} mb={[3]} px={[3,0]} py={[4,0]} border={[`1px solid ${design.color.grayLight}`,'none']} borderRadius={5}>
                  <Text fontWeight={700} fontSize={[3,5]} _mb={[3,4]}>CHF 140.00</Text>
                  <Text flexBasis={['auto',110]} color={'red'} fontWeight={700} fontSize={[2,3]} _mb={[3,4]}>Annulé</Text>
                  <Text flexBasis={['100%','auto']} color={'grayDark'} fontWeight={400} fontSize={[2,3]}><Moment subtract={{ d:0,h:4,m:15 }} format={formats.MDYH} /></Text>
                </Div>
              </Div>

              <Div>
                <Text fontWeight={700} fontSize={[3,5]} mb={[4,5]}>En attente – CHF 120.00</Text>
                <Div display={['flex']} flexWrap={'wrap'} justifyContent={'space-between'} alignItems={'baseline'} mb={[3]} px={[3,0]} py={[4,0]} border={[`1px solid ${design.color.grayLight}`,'none']} borderRadius={5}>
                  <Text fontWeight={700} fontSize={[3,5]} _mb={[3,4]}>CHF 120.00</Text>
                  <Text flexBasis={['auto',110]} color={'text'} fontWeight={400} fontSize={[2,3]}><Div display={['none','inline']}>Acupuncture</Div></Text>
                  <Text flexBasis={['100%','33%']} color={'grayDark'} fontWeight={400} fontSize={[2,3]}><Div display={['inline','none']}>Acupuncture</Div></Text>
                </Div>
              </Div>

              <Box bg={'white'} px={4} py={5} boxShadow={'black'} position={'absolute'} bottom={[-90,-46]} right={[-24,-16]} width={300}>
                <Div display={'flex'} mb={3}>
                  <Text flexBasis={75} fontSize={[3,4]} fontWeight={700} color={'primary'}>Si</Text>
                  <Text fontSize={[3,4]} fontWeight={700}>En attente : 8</Text>
                </Div>
                <Div display={'flex'}>
                  <Text flexBasis={75} fontSize={[3,4]} fontWeight={700} color={'primary'}>Alors</Text>
                  <Text fontSize={[3,4]} fontWeight={700}>Préparation de la facture</Text>
                </Div>
              </Box>
            </Box>
          </Div>
        </Div>

        <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Workflow adaptatif</Title>
            <Text fontSize={[2,4]}>Automatisez la création de vos factures. Définissez vos règles de facturation : par réservation, sur une base mensuelle, etc.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Multi-standard</Title>
            <Text fontSize={[2,4]}>Restez maître de vos standards de facturation. La TVA Suisse et le Tarif520 sont pleinement supportés.</Text>
          </Div>
          <Div mb={6} mr={[0,6]} flex={1}>
            <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Gestion de devis</Title>
            <Text fontSize={[2,4]}>Préparez des devis personnalisés pour vos clients en quelques minutes.</Text>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div position={'relative'} p={[`36px`,6]} mx={[-36,-48]} textAlign={'center'} color={design.color.white} bg={design.color.primary}>
          <FullWidthBg />
          <Div justifyContent={['flex-start','center']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
            <Title href={'/fr/finance#Prepare'} mr={[`64px`,6]} mb={4} fontSize={[3,4]} color={'white'} fontWeight={400}>Préparer</Title>
            <Title id='Release' href={'/fr/finance#Release'} mr={6} mb={4} fontSize={[3,4]} color={'white'} fontWeight={700}>Envoyer</Title>
            <Title href={'/fr/finance#Supervise'} mr={6} mb={4} fontSize={[3,4]} color={'white'} fontWeight={400}>Superviser</Title>
            <Title href={'/fr/finance#Credit'} mb={4} fontSize={[3,4]} color={'white'} fontWeight={400}>Crédit</Title>
          </Div>
          <Title mb={[4,5]} fontSize={[6,7]}>Une image professionnelle pour une plus grande confiance</Title>
          <Text mb={[]} fontSize={[3,4]}>Envoyez facilement des factures au look professionel</Text>
          <Div mx={[-36,0]} display={['block','flex']} mt={[6,110]}>
            <Div flex={1} mb={6}>
              <Title mb={[3,5]} fontSize={[3,6]}>Notifications instantanées</Title>
              <Box boxShadow={'primaryDark'} position={'relative'} width={375} height={812} bg={'white'} backgroundImage={`url('${Phone}')`} backgroundSize={'cover'} m={0} mx={[0,8]} p={[4,6]}>
                <Div position={'absolute'} top={14} left={14} fontSize={15}>
                  <Moment format={formats.HM} />
                </Div>
                <Div position={'absolute'} top={125} right={0} left={0} fontSize={80}>
                  <Moment format={formats.HM} />
                </Div>
                <Div position={'absolute'} top={210} right={0} left={0} fontSize={22}>
                  <Moment format={formats.dDM}/>
                </Div>
                <Div position={'absolute'} top={264} left={20} fontSize={28}>
                  Aujourd'hui
                </Div>
                <Div p={12} position={'absolute'} width={359} borderRadius={12} bg={`rgba(255,255,255,.5)`} top={301} left={8}>
                  <Div fontSize={15} color={`rgba(0,0,0,.4)`} display={'flex'} justifyContent={'space-between'}>
                    <Div display={'flex'}>
                      <img width={20} height={20} src={messages} alt=""/> <Text ml={`7px`}>Message</Text>
                    </Div>
                    <Text>Il y a 5 min</Text>
                  </Div>
                  <Text color={'text'} mt={`8px`} textAlign={'left'}>
                    Nous avons le plaisir de vous informer que votre facture "Blossom Acupuncture" est maintenant disponible. Téléchargement : https://stairl.in/Fo0B4R - Stairlin</Text>
                </Div>
              </Box>
            </Div>
            <Div display={['none','block']} flex={1}>
              <Title mb={[3,5]} fontSize={[3,6]}>Un design de facture élégant</Title>
              <Box boxShadow={'primaryDark'} color={'text'} textAlign={'left'} bg={'white'} height={812} m={0} p={[4,6]}>
                <Div mb={7} display={'flex'}>
                  <img width={64} height={64} src={BALogo} alt=""/>
                  <Div ml={4}>
                    <Text fontSize={6} fontWeight={700}>Facture</Text>
                    <Text fontSize={4}>Blossom Acupuncture</Text>
                  </Div>
                </Div>
                <Div display={'flex'} mb={6}>
                  <Div flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Client</Text>
                    <Text fontSize={3}>John Appleseed
                      Infinite Loop 1
                      8003 Zürich</Text>
                  </Div>
                  <Div ml={4} flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Entreprise</Text>
                    <Text fontSize={3}>Blossom Acupuncture
                      Infinite Loop 1
                      1003 Lausanne</Text>
                  </Div>
                </Div>
                <Div display={'flex'} mb={6}>
                  <Div flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Date</Text>
                    <Text fontSize={3}><Moment format={formats.DMY} /></Text>
                  </Div>
                  <Div ml={4} flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Date limite</Text>
                    <Text fontSize={3}><Moment add={{ d:30 }} format={formats.DMY} /></Text>
                  </Div>
                </Div>
                <Div display={'flex'} mb={6}>
                  <Div flex={1}>
                    <Text mb={2} fontSize={3} fontWeight={700}>Référence</Text>
                    <Text fontSize={3}>IE-86-376-655</Text>
                  </Div>
                </Div>
                <Table width={'100%'}>
                  <Table.Tr>
                    <Table.Th borderBottom={`1px solid #CCC`} py={2}>Description</Table.Th>
                    <Table.Th borderBottom={`1px solid #CCC`} py={2}>Durée</Table.Th>
                    <Table.Th borderBottom={`1px solid #CCC`} py={2} textAlign={'right'}>Total (CHF)</Table.Th>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}>Acupuncture</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}>1h</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} textAlign={'right'}>120.00</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}></Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}>Sous-total</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} textAlign={'right'}>120.00</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}></Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}>TVA<br/>0% de 120.00 (incl.)</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} textAlign={'right'} style={{ verticalAlign: 'bottom' }}>0.00</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2}></Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} fontWeight={700}>Total</Table.Td>
                    <Table.Td borderBottom={`1px solid #CCC`} py={2} textAlign={'right'} fontWeight={700}>120.00</Table.Td>
                  </Table.Tr>
                </Table>
              </Box>
            </Div>
          </Div>

          <Div textAlign={'left'} display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Des notifications multicanals </Title>
              <Text fontSize={[2,4]}>Envoyez vos factures par différents moyens. Le Support inclus les emails, les SMS et WhatsApp.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Des factures personnalisées</Title>
              <Text fontSize={[2,4]}>Téléchargez votre logo et ajoutez un message personalisé, de manière simple.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Plus de 24 devises supportées</Title>
              <Text fontSize={[2,4]}>Facturez vos clients dans plus de 24 devises. Stairlin supporte les USD, EUR, CHF, CBP, JPY et biens d'autres.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id='Supervise' mt={[6,8]}>
          <Div display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div justifyContent={['flex-start','flex-end']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/fr/finance#Prepare'} ml={[0,6]} mr={[`66px`,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Préparer</Title>
                <Title href={'/fr/finance#Release'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Envoyer</Title>
                <Title href={'/fr/finance#Supervise'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={700}>Superviser</Title>
                <Title href={'/fr/finance#Credit'} ml={[0,6]} mr={[6,0]} mb={4} fontSize={[3,4]} fontWeight={400}>Crédit</Title>
              </Div>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Ne ratez plus jamais un paiement</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Faire le suivi de vos factures, c'est du passé. Stairlin se charge de le faire pour vous. </Text>
              </Div>
            </Div>
            <Div flex={1}>
              <Box bg={'white'} m={0} p={[4,6]}>
                <Div display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={5} fontWeight={700}>Activités récentes</Text>
                  <Button display={['none','block']} color={'white'} bg={'primary'} boxShadow={'primary'}>Voir tout</Button>
                </Div>

                <Div>
                  <Div>
                    <Div mt={7}>
                      <Div display={'flex'}>
                        <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.primary}`}>
                          <Div height={[40,47]} width={1} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                          <Div width={20} height={20} borderRadius={'50%'} border={`2px solid ${design.color.white}`}>
                            <Div width={16} height={16} borderRadius={'50%'} bg={`primary`}></Div>
                          </Div>
                        </Div>
                        <Div ml={4}>
                          <Text fontSize={[2,3]} fontWeight={700}>Facture réglée</Text>
                          <Text fontSize={[1,2]} color={'grayDark'}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={5}>
                      <Div display={'flex'}>
                        <Div>
                          <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                            <Div height={[63,46]} width={1} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                          </Div>
                        </Div>
                        <Div ml={4}>
                          <Text fontSize={[2,3]} fontWeight={700}>CHF 120.00 payé par John Appleseed</Text>
                          <Text fontSize={[1,2]} color={'grayDark'}><Moment subtract={{ d:0,h:4 }} format={formats.MDYH} /></Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={5}>
                      <Div display={'flex'}>
                        <Div>
                          <Div position={'relative'} width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                            <Div height={[63,46]} width={1} top={26} left={10} position={'absolute'} borderLeft={`1px solid ${design.color.grayLight}`}></Div>
                          </Div>
                        </Div>
                        <Div ml={4}>
                          <Text fontSize={[2,3]} fontWeight={700}>Envoyé à John Appleseed le 5 juin 2019</Text>
                          <Text fontSize={[1,2]} color={'grayDark'}><Moment subtract={{ d:0,h:4,m:25 }} format={formats.MDYH} /></Text>
                        </Div>
                      </Div>
                    </Div>

                    <Div mt={5}>
                      <Div display={'flex'}>
                        <Div>
                          <Div width={24} height={24} borderRadius={'50%'} border={`2px solid ${design.color.grayLight}`}>
                          </Div>
                        </Div>
                        <Div ml={4}>
                          <Text fontSize={[2,3]} fontWeight={700}>Facture générée depuis la réservation</Text>
                          <Text fontSize={[1,2]} color={'grayDark'}><Moment subtract={{ d:2,h:6,m:11 }} format={formats.MDYH} /></Text>
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </Div>

              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Un suivi transparent</Title>
              <Text fontSize={[2,4]}>Vérifiez l'état de vos paiements et de vos factures. Apercevez immédiatement les factures payées ou en souffrance.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Paiement offline</Title>
              <Text fontSize={[2,4]}>Gérez et suivez les paiements offline. Toutes vos données et informations restent dans un seul endroit.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Rappels</Title>
              <Text fontSize={[2,4]}>Renvoyez vos factures en souffrance avec notre système de suivi.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id='Credit' mt={[6,8]}>
          <Div display={['block','flex']}>
            <Div flex={1}>
              <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/fr/finance#Prepare'} mr={[`63px`,6]} mb={4} fontSize={[3,4]} fontWeight={400}>Préparer</Title>
                <Title href={'/fr/finance#Release'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Envoyer</Title>
                <Title href={'/fr/finance#Supervise'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Superviser</Title>
                <Title href={'/fr/finance#Credit'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Crédit</Title>
              </Div>
              <Div mr={[0,6]} textAlign={['center','left']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Les crédits de manière simple</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Une note de crédit, une supression de paiement, un remboursement ou encore une annulation de facture. Stairlin vous offre une maîtrise de vos crédits</Text>
              </Div>
            </Div>
            <Div flex={1}>
              <Box bg={'white'} height={['auto',640]} m={0} p={[4,6]}>
                <Div display={'flex'} justifyContent={'space-between'}>
                  <Div>
                    <Text fontSize={6} mb={2} fontWeight={700}>CHF 120.00</Text>
                    <Text fontSize={3} color={'green'} fontWeight={700}>Payé</Text>
                  </Div>
                  <Button alignSelf={'flex-start'} display={['none','block']} bg={'primary'} color={'white'} boxShadow={'primary'}>Émission de la note de crédit</Button>
                </Div>
                <Text mb={[5,6]} fontSize={[3,4]} mt={[6,7]} fontWeight={700}>Résumé de la facture</Text>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Facturé à</Text>
                  <Text fontSize={[2,3]} fontWeight={700} color={'primary'}>John Appleseed</Text>
                </Div>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Reférence</Text>
                  <Text fontSize={[2,3]}>IE-86-376-655</Text>
                </Div>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Date</Text>
                  <Text fontSize={[2,3]}><Moment subtract={{ d:5 }} format={formats.DMY} /></Text>
                </Div>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Total</Text>
                  <Text fontSize={[2,3]}>CHF 120.00</Text>
                </Div>
                <Div mb={[4,5]} display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Date limite</Text>
                  <Text fontSize={[2,3]}><Moment add={{ d:25 }} format={formats.DMY} /></Text>
                </Div>
                <Div display={'flex'} justifyContent={'space-between'}>
                  <Text fontSize={[2,3]} fontWeight={700}>Status</Text>
                  <Text fontSize={[2,3]} color={'green'}>Payé</Text>
                </Div>
              </Box>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div position={'relative'} mt={[4,8]} mx={[-36,-48]} color={design.color.white} bg={design.color.primary} p={[36,6]}>
          <FullWidthBg display={['none','block']} />
          <Div display={'flex'} justifyContent={['flex-start','flex-end']} mb={[5,7]}>
            <Title href={'/fr/finance#Billing'} ml={[0,7]} fontSize={[5,8]} fontWeight={400} color={'white'}>Facturation</Title>
            <Title id='Accounting' href={'/fr/finance#Accounting'} ml={[6,7]} fontSize={[5,8]} fontWeight={700} color={'white'}>Comptabilité</Title>
          </Div>

          <Div id='Export' display={['block','flex']} flexDirection={'row-reverse'}>
            <Div flex={1}>
              <Div justifyContent={['flex-start','flex-end']} display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/fr/finance#Export'} ml={[0,6]} mb={4} fontSize={[3,4]} fontWeight={700} color={'white'}>Export</Title>
                <Title href={'/fr/finance#Analyse'} ml={[`113px`,6]} mb={4} fontSize={[3,4]} fontWeight={400} color={'white'}>Analyse</Title>
              </Div>
              <Div ml={[0,6]} textAlign={['center','right']}>
                <Title mb={[4,6]} fontSize={[6,7]}>Un précision au centime prêt</Title>
                <Text mb={[4,6]} fontSize={[3,4]}>Faire de la comptabilité le dimanche, c'est terminé </Text>
              </Div>
            </Div>
            <Div flex={1}>
              <Box boxShadow={'primaryDark'} mx={[-36,0]} color={'text'} bg={'white'} m={0} p={[4,6]}>
                <Text fontSize={[5,6]} fontWeight={700} mb={6}>Transactions</Text>
                <Div display={'flex'} flexWrap={'wrap'}>
                  <Div flex={1} position={'relative'}>
                    <Text bg={'white'} px={`2px`} position={'absolute'} top={-10} left={16} fontSize={1} fontWeight={700}>Période</Text>
                    <Input value={`Dernier trimestre`}/>
                    <Div position={'absolute'} right={14} top={14}><Arrow /></Div>
                  </Div>
                  <Button flexBasis={['100%','auto']} bg={'primary'} color={'white'} boxShadow={'primary'} mt={[4,0]} ml={[0,5]}>Exporter</Button>
                </Div>
              </Box>
            </Div>
          </Div>

          <Div display={['block','flex']} flexWrap={'wrap'} mt={[6,8]}>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}> Mensuel, trimestriel ou annuel</Title>
              <Text fontSize={[2,4]}>Tous vos paiements et factures sont prêts à être exportés pour la période comptable désirée.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Des standards ouverts</Title>
              <Text fontSize={[2,4]}>Exportez vos données en CSV vers tout les standards usuels : Microsoft Excel, Apple Numbers ou Google Spreadsheet.</Text>
            </Div>
            <Div mb={6} mr={[0,6]} flex={1}>
              <Title mb={3} mr={[4,6]} fontSize={[2,4]}>Pas de réconciliation</Title>
              <Text fontSize={[2,4]}>C'est quand même dommage quand les informations de votre logiciel de comptabilité ne correspondent pas à votre relevé bancaire.</Text>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div tag={'section'}>
        <Div id='Analyse' mt={[6,8]}>
          <Div display={['block','flex']}>
            <Div flex={1}>
              <Div display={'flex'} flexWrap={['wrap']} mb={[5,7]}>
                <Title href={'/fr/finance#Export'} mr={6} mb={4} fontSize={[3,4]} fontWeight={400}>Export</Title>
                <Title href={'/fr/finance#Analyse'} mr={6} mb={4} fontSize={[3,4]} fontWeight={700}>Analyse</Title>
              </Div>
              <Div mr={[0,6]} textAlign={['center','left']}>
                <Title display={['block','none']} mb={[4,6]} fontSize={[6,7]}>Une meilleure planification</Title>
                <Title display={['none','block']} mb={[4,6]} fontSize={[6,7]}>Une prévision optimale grâce à des rapports financiers consolidés depuis une seule base de donnée</Title>
              </Div>
            </Div>
            <Div flex={1}>
              <Box bg={'white'} m={0} p={[4,6]} display={'flex'}>
                <Div flex={1}>
                  <Text fontWeight={700} fontSize={[0,4]} mb={[3,4]}>Volume brut</Text>
                  <Text fontWeight={700} fontSize={[2,6]} mb={[4,5]}>CHF 16’200.00</Text>
                  <Text fontWeight={700} fontSize={[0,4]} mb={[3,4]}>Volume net</Text>
                  <Text fontWeight={700} fontSize={[2,6]}>CHF 14’800.00</Text>
                </Div>
                <Div flex={1} display={'flex'} alignItems={'flex-end'} justifyContent={'center'}><Chart /></Div>
              </Box>
            </Div>
          </Div>
        </Div>
      </Div>

      <Div mt={[8]}>
        <TryOrSchedule />
      </Div>

    </Layout>
  )
}
